.site-footer {
    background: $grey;
    * {
        color: $white;
    }
    ul {
        @include list-reset;
        margin-bottom: 0.5em;
        li {
            font-family: $heading-font-family;
            font-size: 2rem;
            a {
                &:hover {
                    color: $lllightpurple;
                }
            }
            > ul {
                li {
                    a {
                        color: $lllightpurple;
                        &:hover {
                            // color: $white;
                        }
                    }
                }
            }
        }
    }
}

.contact-info {
    a:not(.button) {
        color: $grey;
        font-weight: normal;
        &:hover {
            color: $dddarkaqua;
        }
    }
}
.legal {
    text-align: center;
}
