.Icon__split,
.Services {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.Icon__split {
    text-align: center;
    * {
        margin-top: 0;
    }
    .col-5-12 {
        @include media ($large-screen) {
            margin-top: 1em;
        }
    }
    .icon {
        @include media ($phablet-down) {
            padding: 0 0 1em 0;
        }
        @include media ($phablet-up) {
            padding-top: 0;
        }
        svg {
            width: 5em;
            height: auto;
            max-width: 80%;
        }
    }
    .icon.blue {
        &:not(.JLM-Wealth-logo-b) {
            svg path {
                fill: $blue;
            }
        }
    }
    .icon.purple {
        &:not(.JLM-Wealth-logo-b) {
            svg path {
                fill: $purple;
            }
        }
    }
    .icon.grey {
        &:not(.JLM-Wealth-logo-b) {
            svg path {
                fill: $grey2;
            }
        }
    }
}

.Icon__split .icon.JLM-Wealth-logo-b svg {
    width: 10em;
}

.Services {
    text-align: center;
    @include media ($large-screen) {
        padding-left: 10%;
        padding-right: 10%;
    }
    a {
        display: block;
    }
    .row {
        .column {
            @include media ($phablet-up) {
                @include span-columns(3 of 12);
            }
            @include media ($phablet-down) {
                @include span-columns(4 of 8);
                &:nth-child(2) {
                    @include omega;
                }
            }
            &:last-child {
                @include omega;
            }
        }
        > div {
            @include media ($phablet-up) {
                padding: 0;
                transition: margin 0.25s;
                &:hover {
                    margin-top: -3px;
                    margin-bottom: 3px;
                }
            }
            @include media ($phablet-down) {
                padding: 0 2em 2em;
            }
        }
        .Service-icon-wrapper {
            padding: 0 10%;
            @include media ($phablet-up) {
                padding: 0 20%;
                margin-bottom: 1em;
            }
        }
        .Service-icon {
            padding: 20%;
            // max-width: 60%;
            // margin: auto;
            border-width: 4px;
            border-style: solid;
            border-color: $lightcoral;
            border-radius: 50%;
            svg {
                max-width: 100%;
                height: auto;
                path {
                    fill: $coral;
                }
            }
        }
    }
}

.Service-title {
    color: $darkblue;
    @include media ($large-screen-down) {
        font-size: 1em;
        // word-break: break-all;
        // hyphens: auto;
    }
}

// .yellow {
//     * {
//         color: $darkyellow;
//     }
//     .Service-icon {
//         border-color: $lightyellow;
//         svg {
//             fill: $yellow;
//         }
//     }
// }
// .coral {
//     * {
//         color: $darkcoral;
//     }
//     .Service-icon {
//         border-color: $lightcoral;
//         svg {
//             fill: $coral;
//         }
//     }
// }
// .green {
//     * {
//         color: $darkgreen;
//     }
//     .Service-icon {
//         border-color: $lightgreen;
//         svg {
//             fill: $green;
//         }
//     }
// }
// .blue {
//     *:not(.button) {
//         color: $darkblue;
//     }
//     .Service-icon {
//         border-color: $lightblue;
//         svg {
//             fill: $blue;
//         }
//     }
// }
