.Testimonials {
    text-align: center;
    margin: 0 auto;

    .testimonee {
      margin: 1em 0 0 0;
    }
    .testimonial_info {
      color: $grey2;
    }
    .open-quote,
    .close-quote {
        color: $grey2;
        font-size: 1.25em;
    }
}
