input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="week"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="color"],
select {
  border: $border $silver;
  border-radius: $br;
  display: inline-block;
  padding: $sm-pad;
  vertical-align: middle;
}

input:not([type]) {
  -webkit-appearance: none;
  background-clip: padding-box;
  background-color: $white;
  border: $border $silver;
  border-radius: $br;
  color: $black;
  display: inline-block;
  padding: $sm-pad;
  text-align: left;
}

input[type="color"] {
  padding: $sm-pad $md-pad;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select:focus,
textarea:focus {
  border-color: $selection-background-color;
}

input:not([type]):focus {
  border-color: $selection-background-color;
}

input[type="radio"],
input[type="checkbox"] {
  vertical-align: middle;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: $border thin $black;
}

input[type="text"][disabled],
input[type="password"][disabled],
input[type="email"][disabled],
input[type="url"][disabled],
input[type="date"][disabled],
input[type="month"][disabled],
input[type="time"][disabled],
input[type="datetime"][disabled],
input[type="datetime-local"][disabled],
input[type="week"][disabled],
input[type="number"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="color"][disabled],
select[disabled],
textarea[disabled] {
  background-color: $light-grey;
  color: $grey;
  cursor: not-allowed;
}

input:not([type])[disabled] {
  background-color: $light-grey;
  color: $grey;
  cursor: not-allowed;
}

input[readonly],
select[readonly],
textarea[readonly] {
  background-color: $light-grey;
  border-color: $silver;
  color: $grey;
}

input:focus:invalid,
textarea:focus:invalid,
select:focus:invalid {
  border-color: #e9322d;
  color: #b94a48;
}

input[type="file"]:focus:invalid:focus,
input[type="radio"]:focus:invalid:focus,
input[type="checkbox"]:focus:invalid:focus {
  outline-color: $red;
}

select {
  background-color: $white;
  border: $border $silver;
}

select[multiple] {
  height: auto;
}

label {
  line-height: 2;
}

fieldset {
  border: 0;
  margin: 0;
  padding: $sm-pad 0;
}

legend {
  border-bottom: $border $silver;
  color: $black;
  display: block;
  margin-bottom: $sm-pad;
  padding: $sm-pad 0;
  width: 100%;
}

textarea {
  border: $border $silver;
  border-radius: $br;
  display: block;
  margin-bottom: $sm-pad;
  padding: $sm-pad;
  vertical-align: middle;
}
