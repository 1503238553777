.address-footer {
    .logo-address {
        svg {
            margin-bottom: 1em;
        }
    }
    .contact-info {
        @include media ($phablet-up) {
            text-align: right;
        }
        vertical-align: middle;
        margin-top: 2em;
        i {
            color: $grey7;
        }
        a.button i {
            color: $white;
        }
        h3 {
            color: $black;
        }
    }
    @include media ($phablet-down){
        text-align: center;
    }
}
