main,
header,
footer,
article,
section,
aside,
details,
summary {
  display: block;
  height: auto;
  margin: 0 auto;
  width: 100%;
}
//
// main {
//   display: block;
//   margin: 0 auto;
//   max-width: $big-break;
//   padding: 0 $md-pad $md-pad;
// }

// article {
//   clear: left;
//   display: inline-block;
//   float: left;
//   margin-bottom: $md-pad;
//   max-width: calc(60% - 1rem);
// }

// aside {
//   clear: right;
//   display: inline-block;
//   float: right;
//   margin-bottom: $md-pad;
//   max-width: calc(40% - 1rem);
// }
//
// section {
//   clear: both;
//   display: inline-block;
//   float: left;
//   margin-bottom: $md-pad;
//   max-width: 100%;
// }
//
// footer {
//   border-top: .1rem solid $trans-light;
//   clear: both;
//   display: inline-block;
//   float: left;
//   max-width: 100%;
//   padding: 1rem 0;
//   text-align: center;
// }

hr {
  border-top: .1rem solid $trans-light;
  display: block;
  margin-bottom: $md-pad;
  width: 100%;

}

img {
  height: auto;
  max-width: 100%;
  vertical-align: baseline;
}

@media screen and (max-width: $small-break) {
  article,
  section,
  aside {
    clear: both;
    display: block;
    max-width: 100%;
  }

  img {
    margin-right: $md-pad;
  }
}
