.case-study-preview {
    position: relative;
    display: block;
    img {
        position: relative;
        z-index: 0;
        display: block;
        width: 100%;
        height: auto;
    }
    .case-study-description {
        z-index: 10;
        position: relative;
        @include media ($desktop-up) {
            padding: .75em;
            transition: opacity .2s;
            background: $blackt;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0.8;
            *:not(h2) {
                color: $white;
            }
        }
    }
    a.button {
        @include media ($desktop-up) {
            // display: none;
        }
    }
    &:hover .case-study-description {
        opacity: 1;
    }
}
