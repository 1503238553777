.hero {
    position: relative;
    @include clearfix;
    overflow: hidden;
    .hero_image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: center;
        // z-index: 5;
    }
    .hero_content {
        padding: 3em;
        position: relative;
        // z-index: 10;
        &.right {
            float: right;
            text-align: right;
        }
        &.center {
            margin-right: auto;
            margin-left: auto;
            text-align: center;
        }
        > div {
            background: $whitett;
            padding: 1em;
        }
        @include media ($phablet-down) {
            text-align: center;
            padding: 2em 1em;
        }
        @include media ($phablet-large-screen) {
            max-width: 75%;
        }
        @include media ($large-screen) {
            min-height: 30em;
            max-width: 50%;
        }
        h1,
        h2,
        h3 {
            line-height: 1.4em;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            @include media ($phablet-down) {
                line-height: 1.12em;
            }
        }
    }
}

.hero_gradient {
    &.g_top {
        background: linear-gradient($white, transparent);
    }
    &.g_right {
        background: linear-gradient(270deg, $white, transparent);
    }
    &.g_left {
        background: linear-gradient(90deg, $white, transparent);
    }
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.hero.light {
    background: $white;
    text-shadow: 1px 1px 0 $whitet, 0 0 2px $whitet;
    .button {
        text-shadow: none;
    }
    .hero_content > div {
        background: $whitett;
    }
}

.hero.dark {
    background: $blue;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: $white;
        text-shadow: 1px 1px 3px $black;
        text-shadow: 1px 1px 3px $blackt;
    }
    .hero_content > div {
        background: $blackt;
    }
}
