.Knowledge-base {
    // background: $grey4;
    text-align: center;
    form {
        text-align: left;
        width: 100%;
        display: block;
        input {
            display: block;
            width: 100%;
        }
        input[type=text] {
            font-size: 1em;
            font-style: normal;
            outline: none;
            background: $white;
            border: 1px solid $bluet;
            border-radius: 0.25em;
            // box-shadow: $boxsh;
        }
    }
}
form.search-bar {
    ///////////////////////////////////////////////////////////////////////////////////
    $base-spacing: 1.5em !default;
    $base-border-color: gainsboro !default;
    $base-line-height: 1.5em !default;
    //////////////////////////////////////////////////////////////////////////////////
    $search-bar-border-color: $base-border-color;
    $search-bar-border: 1px solid $search-bar-border-color;
    $search-bar-background: lighten($search-bar-border-color, 10%);
    position: relative;
    input[type=text] {
        appearance: none;
        background-color: white;
        box-sizing: border-box;
        display: block;
        font-size: 1em;
        margin: 0;
        padding: ($base-spacing / 3) ($base-spacing / 3);
        position: relative;
        transition: border-color;
        width: 100%;
    }
    button[type=submit] {
        @include position(absolute, 0 0 0 null);
        outline: none;
        padding: 0.25em 1em;
        margin: 0;
        border: 0;
        background: $coral;
        border-top-right-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
        &:hover {
            background: $darkcoral;
        }
        i {
            color: $white;
        }
        img {
            height: 12px;
            opacity: 0.7;
        }
    }
}
.card {
    border: 1px solid $blacktttt;
    border-radius: 0.25em;
    box-shadow: $boxsh;
    padding: 1em;
    h3 {
        text-align: center;
    }
    ul {
        @include list-reset;
        li {
            @include clearfix;
            &:not(:last-child) {
                border-bottom: 1px solid $grey5;
            }
            &:hover {
                background: $darkblue;
                border-bottom-color: $darkblue;
                a,
                i {
                    color: $white;
                }
            }
        }
        i {
            color: $grey3;
            margin: 0;
            line-height: 2.4em;
            clear: left;
            float: left;
            width: 10%;
            display: block;
        }
        a {
            transition: color 0;
            font-weight: normal;
            color: $black;
            width: 90%;
            display: block;
            padding: 0.5em;
            display: block;
        }
    }
}
.Knowledge-base-category {
    svg {
        display: block;
        margin: 0 auto 0.25em;
        width: 2em;
        path {
            fill: $grey2;
        }
    }
}
.demo {
    position: relative;
    *z-index: 1;
    margin: 50px 0;
}
.twitter-typeahead {
    width: 100%;
}
.tt-hint,
.tt-query,
.typeahead {
    width: 500px;
    height: 40px;
    padding: 8px 12px;
    font-size: 24px;
    line-height: 40px;
    border: 1px solid #ccc;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    outline: none;
}
.typeahead {
    background-color: #fff;
}
.typeahead:focus {
    border: 1px solid $blue;
}
.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.tt-hint {
    color: #999;
}
.tt-menu {
    width: 100%;
    margin: 12px 0;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.25em;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
}
.tt-suggestion {
    padding: 3px 20px;
    font-size: 18px;
    line-height: 24px;
}
.tt-suggestion:hover {
    cursor: pointer;
    color: #fff;
    background-color: #0097cf;
}
.tt-suggestion.tt-cursor {
    color: #fff;
    background-color: #0097cf;
}
