body {
	position: relative;
	margin-top: 12em;
	padding-bottom: 3em;
	&.editor-training {
		margin-top: 0;
	}
	@include media ($phablet-down) {
		margin-top: 10em;
	}
}

.Container__wide,
.container {
	@include clearfix;
	margin: 0 auto;
	overflow: visible;
	padding-left: 1.6em;
	padding-right: 1.6em;
	@include media ($phablet-down) {
		padding-left: 1em;
		padding-right: 1em;
	}
}

.container {
	max-width: $large-screen-width;
}

.Container__wide,
.site-footer {
	width: 100%;
	max-width: $super-wide-screen-width;
	margin: 0 auto;
}

.Container__wide,
.cta,
.site-footer {
	width: 100%;
	max-width: $super-wide-screen-width;
	margin: 0 auto;
}

.nopad {
	padding: 0;
}

.row {
	@include clearfix;
	.column,
	[class*="col-"] {
		// padding-left: 0;
		// padding-right: 0;
		@include media ($phablet-down) {
			.site-footer & {
				margin: 2em 0 3em;
			}
		}
	}
	&.col-2 .column {
		@include media ($phablet-up) {
			@include span-columns(6 of 12);
			@include omega(2n);
		}
	}
	&.col-3 .column {
		@include media ($phablet-up) {
			@include span-columns(4 of 12);
			@include omega(3n);
		}
	}
	&.col-4 .column {
		@include media ($phablet-large-screen) {
			@include span-columns(6 of 12);
			@include omega(2n);
			@include omega(4n);
			margin-bottom: 2em;
		}
		@include media ($large-screen) {
			@include span-columns(3 of 12);
			@include omega(4n);
		}
	}
	.col-3-14 {
		@include media ($phablet-up) {
			@include span-columns(3 of 14);
		}
	}
	.col-4-14 {
		@include media ($phablet-up) {
			@include span-columns(4 of 14);
		}
	}
	.col-1-12 {
		@include span-columns(1 of 12);
	}
	.col-2-12 {
		@include media ($phablet-up) {
			@include span-columns(2 of 12);
		}
	}
	.col-4-12 {
		@include media ($phablet-up) {
			@include span-columns(4 of 12);
		}
	}
	.col-1-4 {
		@include media ($phablet-up) {
			@include span-columns(3 of 12);
		}
	}
	.col-5-12 {
		@include media ($phablet-up) {
			@include span-columns(5 of 12);
		}
	}
	.col-1-2 {
		@include media ($phablet-up) {
			@include span-columns(6 of 12);
		}
	}
	.col-1-3 {
		@include media ($phablet-up) {
			@include span-columns(4 of 12);
		}
	}
	.col-2-3 {
		@include media ($phablet-up) {
			@include span-columns(8 of 12);
		}
	}
	.col-8-12 {
		@include media ($phablet-up) {
			@include span-columns(8 of 12);
		}
	}
	.col-11-12 {
		@include span-columns(11 of 12);
	}
}

.white-space {
	padding-top: 2em;
	padding-bottom: 2em;
	@include media ($phablet-up) {
		padding-top: 3em;
		padding-bottom: 3em;
	}
}

.grid {
	.grid-item,
	.grid-sizer {
		width: 100%;
	}
	.grid-item {
		margin-bottom: 2em;
	}
	.gutter-sizer {
		width: 0;
	}
	@include media ($phablet-large-screen) {
		.grid-item,
		.grid-sizer {
			width: 48%;
		}
		.grid-item {
			margin-bottom: 4%;
		}
		.gutter-sizer {
			width: 4%;
		}
	}
	@include media ($large-screen) {
		.grid-item,
		.grid-sizer {
			width: 31.3333333%;
		}
		.grid-item {
			margin-bottom: 3%;
		}
		.gutter-sizer {
			width: 3%;
		}
	}
}

.kb-article,
.kb-share {
	border-bottom: 1px solid $grey4;
	padding-bottom: 2em;
	margin-bottom: 2em;
}

.cmsmap {
	width: 100% !important;
}

.box {
	padding: 15px 10px;
	box-shadow: 0 1px 3px $blackttt, 0 6px 6px $blacktttt;
}

.marb {
	&,
	&.nomar {
		margin-bottom: 10px;
	}
}
.nomar {
	margin: 0;
}
.marbb {
	margin-bottom: 30px;
}
.marbbb {
	margin-bottom: 50px;
}
.mart {
	margin-top: 10px;
}
.martt {
	margin-top: 30px;
}
.marttt {
	margin-top: 50px;
}
.br {
	border-radius: 3px;
	overflow: hidden;
	display: block;
}
.pr {
	position: relative;
}

.text-center {
	text-align: center;
}

.testimonial {
}

.feature_grid {
	@include clearfix();
	.feature_grid-item {
		@include media ($phablet-desktop) {
			width: 50%;
		}
		@include media($desktop-up) {
			width: 25%;
		}
		float: left;
		display: block;
		position: relative;
		background: $ddarkaqua;
		img {
			transition: opacity 0.3s ease-in-out;
			display: block;
			opacity: 0.7;
		}
		&:hover {
			img {
				opacity: 0.35;
			}
		}
		.feature_grid-content {
			padding: 20px;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			* {
				color: $white;
			}
		}
	}
}
