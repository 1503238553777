@mixin list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}

// @mixin colortints($color) {
// 	$light\$color: lighten($color, 10);
// 	$llightcolor: lighten($color, 15);
// 	$lllightcolor: lighten($color, 2);
// 	$darkcolor: darken($color, 15);
// 	$ddarkcolor: darken($color, 25);
// 	$dddarkcolor: darken($color, 35);
// 	$colort: transparentize($color, .2);
// 	$colortt: transparentize($color, .5);
// 	$colorttt: transparentize($color, .75);

// }