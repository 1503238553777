input[type=submit] {
  border: 0;
}
input[type=submit],
.button {
  display: inline-block;
  letter-spacing: .125em;
  margin-bottom: .5em;
  background: $darkaqua;
  color: $white;
  line-height: 1.25em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: $body;
  font-family: $heading-font-family;
  padding: .75em 1.25em;
  font-size: 1em;
  &.small {
    padding: .6em 1em;
    font-size: .8em;
  }

  &:hover {
    background: $ddarkaqua;
    box-shadow: $boxsh;
    color: $white;
  }

  &.secondary {
    background: $grey;
    color: $white;

    &:hover {
      background: $darkgrey;
      box-shadow: $boxsh;
    }
  }
  &.tertiary {
    background: $grey2;
    color: $white;

    &:hover {
      background: $grey0;
      box-shadow: $boxsh;
    }
  }
}
