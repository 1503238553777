.Image {
	img {
		width: 100%;
		height: auto;
		display: block;
	}
}

img.round {
	border-radius: 50%;
}

.image {
	position: relative;
	img {
		width: 100%;
		height: auto;
		display: block;
	}
	.image-desc {
		position: absolute;
		bottom: 0;
		padding: 0.5em;
		background: $blacktt;
		p {
			font-size: 0.75em;
			margin: 0;
			color: $whitettt;
			&:hover {
				color: $whitet;
			}
		}
	}
}

.circular-image {
	.Case-Studies & {
		margin-top: 2em;
	}
	.Testimonials & {
	}
	.case-study &,
	.Testimonials & {
		margin: 0 auto 1em;
		width: 250px;
		height: 250px;
	}
	img {
		width: 100%;
		display: block;
		width: 250px;
		height: 250px;
	}
	border-radius: 50%;
	overflow: hidden;
}

.logo {
	svg {
		$logowidth: 172.562px;
		$logoheight: 97.916px;
		width: $logowidth;
		height: $logoheight;
		.site-header & {
			@include media ($large-screen-down) {
				width: ($logowidth * 0.75);
				height: ($logoheight * 0.75);
			}
			@include media ($phablet-down) {
				width: ($logowidth * 0.6);
				height: ($logoheight * 0.6);
			}
		}
	}
	svg {
		path.jlm {
			stroke: $grey;
		}
		path.text {
			fill: $lllightgrey;
		}
	}
	a:hover {
		svg {
			path.text {
				fill: $grey;
			}
			path.jlm {
				stroke: $darkgrey;
				fill: $greytttt;
			}
		}
	}
}

.accreditations {
	text-align: center;
	.accreditation {
		display: inline-block;
		height: 60px;
		width: auto;
		margin: 1em;
		opacity: 0.3;
		transition: opacity 0.3s;
		&:hover {
			opacity: 1;
		}
		@include media ($phablet-down) {
			display: block;
			margin: 0.5em auto;
		}
	}
}

.testimonial_image {
	padding: 10px 40px;
	img {
		width: 300px;
		max-width: 100%;
		margin: 0 auto;
		height: auto;
		border-radius: 50%;
		display: block;
	}
}
