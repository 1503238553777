.Social {
text-align: center;
padding: 1em;
  background: $grey3;
  .main-nav & {
    background: none;
    border-top: 1px solid $grey;
    margin-top: 3em;
    a:hover {
      border: none;
    }
  }
  a {
    color: $white;
    font-size: 2em;
    @include media ($phablet-down){
      font-size: 1.4em;
    }
    padding: 0 1em;
    transition: color 100ms;
    &:hover {
      color: $aqua;
    }
  }
}
