nav {

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: $md-pad;
    text-align: center;

    li {
      display: inline;
    }
  }

  a {
    border-bottom: .2rem solid rgba(0, 0, 0, 0);
    color: $black;
    // padding: $sm-pad $md-pad;
    text-decoration: none;
    transition: $transition;

    &:hover {
      border-color: $trans-light;
    }

    &:active {
      border-color: $trans-mid;
    }
  }
}
