$color-stack: (group: primary, id: normal, color: $purple), (group: complement, id: normal, color: $white);
@function color($group, $shade:normal, $transparency:1) {
     @each $color in $color-stack{
        $c-group: map-get($color, group);
        $c-shade: map-get($color, id);
        @if($group == map-get($color, group) and $shade == map-get($color, id)) {
             @return rgba(map-get($color, color), $transparency);
        }
    }
}
$b-height: 40px; //Height (and width) of the burger menu
$b-margin-top: 30px; //Burger menu margin (distance from top)
$b-margin-left: 30px; //Burger menu margin (distance from left)
$b-border-width: 2px; //Width of the borders and 'buns'
$b-bun-width: 20px; //Width of the 'buns'
$b-bun-height: $b-border-width; //Height of the 'buns'
$b-border-radius: 50%; //Change the border radius of the menu
$b-trans-speed: 0.3s; //Transition speed settings
$b-transition: all $b-trans-speed ease; //Transitions
$b-font-size: 24px; //Font size of brand and links
$b-font-family: Helvetica-Neue, Helvetica, Arial, sans-serif; //Font family of the brand and links
$b-font-weight: 300; //Font weight of brand and links
$b-txt-pad: 30px; //Left padding on both brand and links
$b-line-height: ($b-height / 2 - ($b-font-size / 2.8)); //Vertically centers brand to menu
$b-txt-pad-top: .5em; //Top margin for first link element
$b-bun-width-half: ($b-bun-width / 2); //Half bun width used for calculation
$b-pad-left: ($b-height / 2 - $b-bun-width-half - 2); //Horizontally centers bun in menu
$b-pad-top: ($b-height / 2 - $b-bun-width-half); //Vertically centers bun in menu
@keyframes slideInLeft {
    0% {
        transform: translate3d(-250px, 0, 0);
        visibility: visible;
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes slideOutLeft {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-250px, 0, 0);
        visibility: hidden;
    }
}
* {
    box-sizing: border-box;
}
// body {
//     &:after {
//         background: color(primary);
//         content: '';
//         height: 100%;
//         left: 0;
//         opacity: 0;
//         overflow: auto;
//         padding: 0;
//         position: absolute;
//         top: 0;
//         transition: $b-transition;
//         width: 100%;
//     }
//     &.open {
//         overflow: hidden;
//     }
//     &.open:after {
//         opacity: 1;
//         visibility: visible;
//     }
// }
// @include media ($phablet-down) {
    .b-nav {
        position: relative;
        z-index: 11;
        &:not(.open) {
            visibility: hidden;
            height: 20px;
        }
        li {
            color: color(complement);
            list-style-type: none;
            // padding: 10px;
            text-align: left;
            transform: translateX(-250px);
        }
        li:not(.open) {
            animation-duration: $b-trans-speed;
            animation-fill-mode: both;
            animation-name: slideOutLeft;
        }
        li:first-child {
            // padding-top: $b-txt-pad-top;
        }
        &.open {
            visibility: visible;
            li {
                animation-duration: $b-trans-speed;
                animation-fill-mode: both;
                animation-name: slideInLeft;
            }
        }
    }
    .b-link {
        background: transparent;
        border-left: color(complement, normal, 0) solid $b-border-width;
        color: color(complement);
        font-family: $b-font-family;
        font-size: $b-font-size;
        font-weight: $b-font-weight;
        margin-left: $b-margin-left;
        text-decoration: none;
        transition: $b-transition;
        width: auto;
        &--active,
        &:hover {
            border-left: color(complement) solid $b-border-width;
            padding-left: $b-txt-pad;
        }
    }
    .b-menu, .b-menu2 {
        background: color(primary);
        border: color(primary) solid $b-border-width;
        border-radius: $b-border-radius;
        cursor: pointer;
        display: inline-block;
        height: $b-height;
        padding-left: .5em;
        padding-top: .6em;
        position: relative;
        transition: $b-transition;
        user-select: none;
        width: $b-height;
        z-index: 12;
        &:hover {
            // border: color(complement) solid $b-border-width;
        }
    }
    .b-menu {
        background: $llightblue;
        border: 2px solid $llightblue;
    }
    .b-bun {
        background: color(complement);
        position: relative;
        transition: $b-transition;
        &--top {
            height: $b-bun-height;
            top: 0;
            width: $b-bun-width;
        }
        &--mid {
            height: $b-bun-height;
            top: 5px;
            width: $b-bun-width;
        }
        &--bottom {
            height: $b-bun-height;
            top: 10px;
            width: $b-bun-width;
        }
    }
    .b-brand {
        color: color(primary);
        font-family: $b-font-family;
        font-size: $b-font-size;
        font-weight: $b-font-weight;
        margin-left: $b-txt-pad;
        position: relative;
        text-decoration: none;
        top: -$b-line-height;
        transition: $b-transition;
        z-index: 13;
    }
    .b-container {
        height: $b-height;
        right: 1em;
        position: absolute;
        top: 1.5em;
        @include media ($phablet-up){
            right: 1.6em;
        }
        &:hover:not(.open) {
            .bun-bottom,
            .bun-mid,
            .bun-top {
                background: color(primary);
            }
        }
        &.open {
            .b-menu, .b-menu2 {
                padding-top: .5em;
                padding-left: .5em;
            }
            .b-main {
                border: color(complement) solid $b-border-width;
            }
            .b-bun--top {
                background: color(complement);
                top: 9px;
                transform: rotate(45deg);
            }
            .b-bun--mid {
                opacity: 0;
            }
            .b-bun--bottom {
                background: color(complement);
                top: 5px;
                transform: rotate(-45deg);
            }
            .b-brand {
                color: color(complement);
            }
        }
    }
// }
