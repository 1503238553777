table {
  margin-bottom: $md-pad;
}

caption {
  padding: $sm-pad 0;
}

thead th {
  background: $light-grey;
  color: $black;
}

tr {
  background: $white;
  margin-bottom: $sm-pad;
}

th,
td {
  border: $border $silver;
  padding: $sm-pad $md-pad;
  text-align: center;
  vertical-align: inherit;
}

tfoot tr {
  background: none;
}

tfoot td {
  color: $light-grey;
  font-size: $sm-pad;
  font-style: italic;
  padding: $md-pad $xs-pad;
}
