$title: $heading-font-family;
$body: $sans-serif-font-family;
p a,
p,
ul {
	font-family: $body;
	font-size: 16px;
	strong {
		color: $darkblack;
	}
}

p.small,
p.small a {
	font-size: 14px;
}

.grey {
	color: $grey5;
}

a {
	font-weight: bold;
	color: $grey;
	&:hover {
		color: $ddarkaqua;
	}
}

h1,
h2,
h3 {
	font-family: $title;
	font-weight: normal;
	@include media ($phablet-down) {
		font-size: 1.3em;
	}
}

h1 {
	font-size: 2.5em;
	font-family: $title;
	// color: $darkaqua;
	color: $black;
	border: 0;
}

h2 {
	color: $dddarkaqua;
}

h3 {
	color: $darkgrey;
}

h4,
h5,
h6 {
	font-family: $body;
	text-transform: uppercase;
	color: $grey3;
}

h4 {
	color: $aqua;
}

.center {
	text-align: center;
}

.row .column {
	h1:first-child,
	h2:first-child,
	h3:first-child,
	h4:first-child,
	h5:first-child {
		margin-top: 0;
	}
}

.inverted-comma {
	color: $lllightaqua;
	font-size: 40px;
}

.grey {
	color: $lightgrey;
}

.lllightaqua {
	color: $lllightaqua;
}
.llightaqua {
	color: $llightaqua;
}
.lightaqua {
	color: $lightaqua;
}
.aqua {
	color: $aqua;
}
