.cta {
    text-align: center;
    padding: 1em 1.6em;
    @include media($phablet-up) {
        padding: 1.6em 6em;
    }
    overflow: auto;
    &.black,
    &.purple {
        *:not(.button) {
            color: $white;
        }
    }
    &.purple {
        background: $ddarkpurple;
    }
    &.black {
        background: $black;
    }
    &.white {
        background: white;
    }
    h1,
    h2,
    h3,
    h4,
    p {
        // margin: 0;
        @include media ($phablet-down) {
            line-height: 1.2em;
        }
    }
}
