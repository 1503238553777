//Fonts
$sans-serif-font-family:        'Open Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', sans-serif;
$serif-font-family:             'Roboto Slab', serif;
$heading-font-family:           'Oswald', serif;
$monospace-font-family:         Menlo, Monaco, Consolas, 'Courier New', monospace;

//Santize.scss
//Root
$root-box-sizing:               border-box !default;
$root-cursor:                   default !default;
$root-font-family:              $sans-serif-font-family !default;
$root-font-size:                1rem !default;
$root-line-height:              1.4 !default;
$root-text-rendering:           optimizeLegibility !default;

//Other
$anchor-text-decoration:        none !default;
$form-element-background-color: transparent !default;
$form-element-min-height:       if(unitless($root-line-height), $root-line-height * 1em, if(unit($root-line-height) != '%', $root-line-height, null)) !default;
$media-element-vertical-align:  middle !default;
$monospace-font-family:         $monospace-font-family !default;
$nav-list-style:                none !default;
$selection-background-color:    #b3d4fc !default;
$selection-text-shadow:         none !default;
$small-font-size:               75% !default;
$big-font-size:                 125% !default;
$table-border-collapse:         collapse !default;
$table-border-spacing:          0 !default;
$textarea-resize:               vertical !default;

//Basic stylings
$br:                            0;
$xs-pad:                        .4rem;
$sm-pad:                        .8rem;
$md-pad:                        1.6rem;
$lg-pad:                        2rem;
$xlg-pad:                       4rem;
$transition:                    .25s ease;
$border:                        .1rem solid;

//Breakpoints
$small-break:                   40rem;
$big-break:                     76.8rem;

//Colors
$primary-color:                 #1271db;
$red:                           #ff4136;

//Greyscale
$white:                         #fff;
$light-grey:                    #efefef;
$silver:                        #ccc;
$grey:                          #777;
$black:                         #444;

//Transparency
$trans-light:                   rgba(0, 0, 0, .2);
$trans-mid:                     rgba(0, 0, 0, .56);
$trans-dark:                    rgba(0, 0, 0, .87);
